import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showModal } from 'src/core/modal';
import { formatDate } from 'src/utils/dateUtils';

import { getFIOShort } from 'utils/textUtils';

import { Card } from 'components/Card/Card';
import { CardValue } from 'components/CardValue/CardValue';
import { TextButton } from 'components/TextButton/TextButton';

import { ArrivalModal } from 'modules/Situations/components/ArrivalModal/ArrivalModal';
import { DepartureModal } from 'modules/Situations/components/DepartureModal/DepartureModal';
import { getStatusField } from 'modules/Situations/utils';

import styles from './SituationCardList.module.scss';

export const SituationCardList = ({ data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.situationCardList}>
            {data?.map((item) => (
                <Card
                    key={item.id}
                    title={item.index}
                    status={getStatusField(item, t)}
                    onClick={() => navigate(`/situations/${item.id}`)}
                >
                    <CardValue
                        label={t('situations:train_num')}
                        value={item.nomPoezd}
                    />
                    <CardValue
                        label={t('station')}
                        value={item.arrivalStation?.name}
                    />
                    <CardValue
                        label={t('situations:security')}
                        value={item.guardedWagonsCount}
                    />
                    <CardValue
                        label={t('situations:security_person')}
                        value={
                            item.acceptGuard
                                ? getFIOShort(item.acceptGuard)
                                : ''
                        }
                    />
                    <CardValue
                        label={t('situations:arrival')}
                        value={
                            <TextButton
                                label={
                                    item.arrivalDate
                                        ? formatDate(item.arrivalDate)
                                        : `+${t('actions.add')}`
                                }
                                onClick={() =>
                                    showModal(ArrivalModal, { id: item.id })
                                }
                                color={item.arrivalDate ? undefined : 'green'}
                            />
                        }
                    />
                    <CardValue
                        label={t('situations:departure')}
                        value={
                            item.departureDate || item.arrivalDate ? (
                                <TextButton
                                    label={
                                        item.departureDate
                                            ? formatDate(item.departureDate)
                                            : `+${t('actions.add')}`
                                    }
                                    onClick={() => {
                                        showModal(DepartureModal, {
                                            id: item.id
                                        });
                                    }}
                                    color={
                                        item.departureDate ? undefined : 'green'
                                    }
                                />
                            ) : null
                        }
                    />
                </Card>
            ))}
        </div>
    );
};

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';

import { Form } from 'components/Form/Form';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { FormSelectField } from 'components/Form/FormSelectField';

import styles from './CarriageModal.module.scss';

export const CarriageModal = createModal(
    (props) => {
        const { t } = useTranslation();

        const methods = useForm({
            defaultValues: {}
        });

        return (
            <Modal
                title={'№1. 59654328'}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'sm'}
                {...props}
            >
                <Form methods={methods}>
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={16}>
                                <Col span={6}>
                                    <span className={styles.title}>
                                        Закрутки: 5
                                    </span>
                                </Col>
                                <Col span={6}>
                                    <span className={styles.title}>КП: 1</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="divider" />
                        <div className="modalPadding">
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <span className={styles.subTitle}>
                                        Пломбы с лев:
                                    </span>
                                    <div className={styles.fields}>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <span className={styles.subTitle}>
                                        Помбы с прав:
                                    </span>
                                    <div className={styles.fields}>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                        <span className={styles.field}>
                                            спр: 8251826-833.
                                        </span>
                                    </div>
                                </Col>
                                <Col span={[12]}>
                                    <span className={styles.info}>
                                        Отсутствует ЗПУ
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <div className="divider" />
                        <div className="modalPadding">
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <FormSegmentControlField
                                        name="name"
                                        noHintSpace={true}
                                        variant="button"
                                        segmentsConfig={[
                                            {
                                                label: 'Парк',
                                                value: 'Парк'
                                            },
                                            {
                                                label: 'Станция',
                                                value: 'Станция'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormSelectField
                                        name="status_eq"
                                        options={[]}
                                        noHintSpace={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'CarriageModal'
    }
);

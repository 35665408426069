import { StatusText } from 'components/StatusText/StatusText';

import {
    ACCEPTED,
    ACCEPTED_WITHOUT_DEFECT,
    ACCEPTED_WITH_DEFECT,
    ACCEPTED_WITH_GFA,
    AWAITING_ACCEPTANCE,
    DETACHED,
    REJECTED
} from './consts';

export const getStatusField = (data = {}, t) => {
    switch (data.status) {
        case DETACHED:
            return (
                <StatusText
                    label={t('applications:not_accepted')}
                    variant="orange"
                />
            );
        case AWAITING_ACCEPTANCE:
            return (
                <StatusText label={t('applications:aproach')} variant="grey" />
            );
        case ACCEPTED:
            return (
                <StatusText
                    label={t('applications:secure_accepted')}
                    variant="green"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};

export const getApplicationStatusField = (data = {}, t) => {
    switch (data.status) {
        case DETACHED:
            return (
                <StatusText
                    label={t('applications:status_cargo.DETACHED')}
                    variant="red"
                />
            );
        case AWAITING_ACCEPTANCE:
            return (
                <StatusText
                    label={t('applications:status_cargo.AWAITING_ACCEPTANCE')}
                    variant="orange"
                />
            );
        case ACCEPTED:
            return (
                <StatusText
                    label={t('applications:status_cargo.ACCEPTED')}
                    variant="green"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};

export const getCarriageStatusFields = (data = {}, t) => {
    switch (data.status) {
        case REJECTED:
            return (
                <StatusText
                    label={t('applications:status_cargo.REJECTED')}
                    variant="red"
                />
            );
        case ACCEPTED_WITH_GFA:
            return (
                <StatusText
                    label={t('applications:status_cargo.ACCEPTED_WITH_GFA')}
                    variant="orange"
                />
            );
        case ACCEPTED_WITHOUT_DEFECT:
            return (
                <StatusText
                    label={t('applications:status_cargo.ACCEPTED_WITHOUT_DEFECT')}
                    variant="green"
                />
            );
        case ACCEPTED_WITH_DEFECT:
            return (
                <StatusText
                    label={t('applications:status_cargo.ACCEPTED_WITH_DEFECT')}
                    variant="green"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};

export const getStatusOptions = (t) => {
    return [
        {
            label: t('applications:status_cargo.ACCEPTED_WITHOUT_DEFECT'),
            value: ACCEPTED_WITHOUT_DEFECT
        },
        {
            label: t('applications:status_cargo.ACCEPTED_WITH_GFA'),
            value: ACCEPTED_WITH_GFA
        },
        {
            label: t('applications:status_cargo.ACCEPTED_WITH_DEFECT'),
            value: ACCEPTED_WITH_DEFECT
        },
        {
            label: t('applications:status_cargo.REJECTED'),
            value: REJECTED
        }
    ];
};

export const getApplicationStatusOptions = (t) => {
    return [
        {
            label: t('applications:status_cargo.AWAITING_ACCEPTANCE'),
            value: AWAITING_ACCEPTANCE
        },
        {
            label: t('applications:status_cargo.ACCEPTED'),
            value: ACCEPTED
        },
        {
            label: t('applications:status_cargo.DETACHED'),
            value: DETACHED
        }
    ];
};

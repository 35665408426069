import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';

import { showModal } from 'core/modal';

import { formatDate } from 'utils/dateUtils';
import { notificateErrorResponse } from 'utils/serverErrors';
import { getFIOShort } from 'utils/textUtils';

import { CellField } from 'components/CellField/CellField';
import { TableRowEditControls } from 'components/TableRowEditControls/TableRowEditControls';
import { TextButton } from 'components/TextButton/TextButton';
import { TextLink } from 'components/TextLink/TextLink';

import { ArrivalModal } from 'modules/Situations/components/ArrivalModal/ArrivalModal';
import { DepartureModal } from 'modules/Situations/components/DepartureModal/DepartureModal';
import { SituationEditModal } from 'modules/Situations/components/SituationEditModal/SituationEditModal';
import { SITUATION_PAGE_SIZE } from 'modules/Situations/components/consts';
import { deleteSituation } from 'modules/Situations/services';
import { getStatusField } from 'modules/Situations/utils';

export const SituationsPostTable = ({ data, refetch }) => {
    const { t } = useTranslation();

    const deleteMutation = useMutation({
        mutationFn: deleteSituation,
        onSuccess: refetch,
        onError: (error) => notificateErrorResponse(error, t)
    });

    return (
        data?.length > 0 && (
            <Table
                data={data ?? []}
                columns={getColumnsConfig(t, deleteMutation.mutate)}
                pageSize={SITUATION_PAGE_SIZE}
            />
        )
    );
};

function getColumnsConfig(t, onDelete) {
    return [
        {
            header: t('situations:index'),
            accessorKey: 'index',
            cell: ({ row }) => <CellField value={row.original.indexPoezd} />,
            minSize: 100,
            size: 200
        },
        {
            header: t('situations:train_num'),
            cell: ({ row }) => (
                <TextLink
                    label={row.original.nomPoezd}
                    url={`/situations/${row.original.id}`}
                />
            ),
            enableSorting: true,
            size: 130
        },
        {
            header: t('situations:arrival'),
            cell: ({ row }) =>
                row.original.arrivalDate ? (
                    <TextButton
                        label={formatDate(row.original.arrivalDate)}
                        onClick={() =>
                            showModal(ArrivalModal, {
                                id: row.original.id
                            })
                        }
                    />
                ) : (
                    <TextButton
                        label={`+${t('actions.add')}`}
                        color="green"
                        onClick={() =>
                            showModal(ArrivalModal, {
                                id: row.original.id
                            })
                        }
                    />
                ),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:departure'),
            cell: ({ row }) => {
                const { departureDate, arrivalDate, id } = row.original;
                const handleClick = () => {
                    showModal(DepartureModal, { id });
                };

                return departureDate || arrivalDate ? (
                    <TextButton
                        label={
                            departureDate
                                ? formatDate(departureDate)
                                : `+${t('actions.add')}`
                        }
                        color={departureDate ? undefined : 'green'}
                        onClick={handleClick}
                    />
                ) : null;
            },
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('station'),
            accessorKey: 'arrivalStation.name',
            enableSorting: true
        },
        {
            header: t('situations:security'),
            accessorKey: 'guardedWagonsCount',
            enableSorting: true
        },
        {
            header: t('situations:security_person'),
            accessorKey: 'acceptGuard.username',
            cell: ({ row }) => {
                return row.original.acceptGuard
                    ? getFIOShort(row.original.acceptGuard)
                    : '';
            },
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('status'),
            cell: ({ row }) => getStatusField(row.original, t),
            minSize: 150,
            size: 150,
            enableSorting: true
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                return (
                    <TableRowEditControls
                        onEdit={() =>
                            showModal(SituationEditModal, {
                                id: row.original.id
                            }).then(noop, noop)
                        }
                        onDelete={() =>
                            showModal('confirm-delete').then(
                                () => onDelete(row.original.id),
                                noop
                            )
                        }
                    />
                );
            },
            size: 75,
            minSize: 75
        }
    ];
}

import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { SHOW_LIST_OF_ESCORTS, SHOW_LIST_OF_ONCOMING } from 'core/permissions';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useIsPermissionEnabled } from 'hooks/useIsPermissionEnabled';
import { useSize } from 'hooks/useSize';

import { CargoEscortList } from 'modules/Situations/components/CargoEscortList/CargoEscortList';
import { CargoOncomingList } from 'modules/Situations/components/CargoOncomingList/CargoOncomingList';
import { SituationsFilters } from 'modules/Situations/components/SituationsFilters/SituationsFilters';
import { SituationsHeader } from 'modules/Situations/components/SituationsHeader/SituationsHeader';
import { SituationsPost } from 'modules/Situations/components/SituationsPost/SituationsPost';
import { getSituationsList } from 'modules/Situations/services';

export const SituationsDashboardPage = () => {
    const ref = useRef();
    const { showSmall } = useSize(ref);
    const isPermissionEnabled = useIsPermissionEnabled();
    const userStationCode = useSelector(
        (state) => state.auth.user.profileDataMap?.STATION_CODE
    );
    const [customFilters] = useQueryParam(
        'customFilters',
        withDefault(CustomFilterParam, {
            arrival_station_code_eq: userStationCode
        })
    );
    const [activePost] = useQueryParam(
        'activePost',
        withDefault(StringParam, 'all')
    );
    const situationPosts = useDataRequest('situationsList', getSituationsList, {
        customFilters
    });

    return (
        <div className="page-content" ref={ref}>
            <SituationsHeader />
            <SituationsFilters
                isLoading={situationPosts.isLoading}
                situationPosts={situationPosts.data}
            />
            <SituationsPost
                isLoading={situationPosts.isLoading}
                situationPosts={situationPosts}
                activePost={activePost}
            />
            {!showSmall &&
                isPermissionEnabled(SHOW_LIST_OF_ESCORTS) &&
                displayCargo(activePost) && <CargoEscortList />}
            {!showSmall &&
                isPermissionEnabled(SHOW_LIST_OF_ONCOMING) &&
                displayOncoming(activePost) && <CargoOncomingList />}
        </div>
    );
};

const displayCargo = (activePost) => {
    return !activePost || activePost === 'all' || activePost === 'escort';
};

const displayOncoming = (activePost) => {
    return !activePost || activePost === 'all' || activePost === 'oncoming';
};

import { useQueryParam } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useScreenSize } from 'hooks/useScreenSize';
import { useSort } from 'hooks/useSort';

import { DefectCardsList } from 'modules/Defects/components/DefectCardsList/DefectCardsList';
import { DefectsHeader } from 'modules/Defects/components/DefectsHeader/DefectsHeader';
import { DefectsTable } from 'modules/Defects/components/DefectsTable/DefectsTable';
import { defectInitialSort } from 'modules/Defects/consts';
import { getDefectList } from 'modules/Defects/services';

export const DefectsPage = () => {
    const { isMobile } = useScreenSize();

    const [customFilters] = useQueryParam('customFilters', CustomFilterParam);

    const [sort, setSort] = useSort(defectInitialSort);

    const defects = useDataRequest('defectsList', getDefectList, {
        customFilters,
        sort
    });

    return (
        <>
            <DefectsHeader refetch={defects.refetch} />
            {isMobile ? (
                <DefectCardsList
                    data={defects.data}
                    isFetching={defects.isLoading}
                />
            ) : (
                <DefectsTable
                    isLoading={defects.isLoading}
                    defects={defects.data}
                    sort={sort}
                    setSort={setSort}
                />
            )}
        </>
    );
};

import { useSelector } from 'react-redux';
import { useDataRequest } from 'src/core/queries/useDataRequest';
import { useSort } from 'src/hooks/useSort';
import 'src/utils/extendedFiltersUtil';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

import { useScreenSize } from 'hooks/useScreenSize';

import { ApplicationCardsList } from '../components/ApplicationCardsList/ApplicationCardsList';
import { ApplicationsHeader } from '../components/ApplicationHeader/ApplicationHeader';
import { ApplicationTable } from '../components/ApplicationTable/ApplicationTable';
import { applicationsInitialSort } from '../consts';
import { getCargosListNonPaged } from '../services/services';

export const ApplicationPage = () => {
    const { isMobile } = useScreenSize();

    const userStationCode = useSelector(
        (state) => state.auth.user.profileDataMap?.STATION_CODE
    );

    const [customFilters] = useQueryParam(
        'customFilters',
        withDefault(CustomFilterParam, {
            appearance_station_code_eq: userStationCode
        })
    );

    const [sort, setSort] = useSort(applicationsInitialSort);

    const { isFetching, data, refetch } = useDataRequest(
        'getCargosListNonPaged',
        getCargosListNonPaged,
        {
            sort,
            customFilters
        }
    );

    return (
        <div>
            <ApplicationsHeader refetch={refetch} />
            {isMobile ? (
                <ApplicationCardsList data={data} isFetching={isFetching} />
            ) : (
                <ApplicationTable
                    data={data}
                    sort={sort}
                    refetch={refetch}
                    setSort={setSort}
                    isFetching={isFetching}
                />
            )}
        </div>
    );
};

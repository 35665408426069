import { useMutation, useQuery } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Tabs } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Form } from 'components/Form/Form';

import { AWAITING_ACCEPTANCE } from 'modules/Applications/consts';
import {
    createCarriage,
    getCargoAppWalogList,
    getCargosList
} from 'modules/Applications/services/services';

import { Carriage } from '../Carriage/Carriage';
import { CarriageContainer } from '../CarriageContainer/CarriageContainer';
import styles from './CarriageContent.module.scss';

const CARRIAGE_TYPE = 'CARRIAGE_TYPE';
const BOX_TYPE = 'BOX_TYPE';

const DEFAULT_TABS = [
    {
        value: 0,
        label: 'applications:carriage',
        type: CARRIAGE_TYPE,
        index: 0
    }
];

export const CarriageContent = () => {
    const { t } = useTranslation();
    const [tabs, setTabs] = useState(DEFAULT_TABS);
    const [selectedTab, setSelectedTab] = useState(DEFAULT_TABS[0]);
    const { isMobile } = useScreenSize();

    const navigate = useNavigate();
    const { id, carriageId } = useParams();

    const { data, isFetching: cargoFetching } = useQuery({
        queryKey: ['getCargoAppWalogListItem', carriageId],
        queryFn: () => getCargoAppWalogList({ id_eq: carriageId }),
        enabled: !!carriageId,
        initialData: []
    });

    const { data: applicationData, isFetching: applicationDataIsFetching } =
        useQuery({
            queryKey: ['getCargosList', id],
            queryFn: () =>
                getCargosList({
                    id_eq: id
                }),
            enabled: !!id,
            initialData: []
        });

    const methods = useForm({
        defaultValues: {
            attributes: null,
            containers: null,
            detached: null,
            initialStatus: '',
            remark: '',
            wagonCode: ''
        }
    });

    const carriageFields = useFieldArray({
        control: methods.control,
        name: 'attributes',
        keyName: 'carriageAttributesFieldId'
    });

    const containerFields = useFieldArray({
        control: methods.control,
        name: 'containers',
        keyName: 'containerAttributesFieldId'
    });

    useEffect(() => {
        if (data.length && carriageId) {
            methods.reset(data[0]);
            setTabs([
                ...DEFAULT_TABS,
                ...data[0]?.containers.map((container, i) => {
                    return {
                        label: 'applications:box',
                        subTitle: container.containerCode,
                        type: BOX_TYPE,
                        value: +i + 1
                    };
                })
            ]);
        }
    }, [data, methods, carriageId]);

    const handleClick = () => {
        containerFields.append({
            containerCode: null,
            containersSeals: [],
            containersTwists: [],
            type: 'CONTAINER'
        });
        setTabs([
            ...tabs,
            {
                value: tabs.length,
                label: 'applications:box',
                type: BOX_TYPE
            }
        ]);
        setSelectedTab({
            value: tabs.length,
            label: 'applications:box',
            type: BOX_TYPE
        });
    };

    const handleDeleteTab = (id) => {
        setSelectedTab(tabs.find((tab) => tab.value === id));
        setTabs(tabs.filter((tab) => tab.value !== id + 1));
        containerFields.remove(id);
    };

    const handleSelect = (e) => {
        setSelectedTab(tabs.find((tab) => tab.value === e));
    };

    const mutation = useMutation({
        mutationFn: (state) => {
            return createCarriage({ ...state, appId: id });
        },
        onSuccess: () => navigate(`/applications/reception/${id}`)
    });

    const isFetching =
        mutation.isPending || cargoFetching || applicationDataIsFetching;

    const isDisabled =
        isFetching || applicationData[0]?.status !== AWAITING_ACCEPTANCE;

    return (
        <div className={styles.content}>
            <div className={styles.tabs}>
                <Tabs
                    tabs={getTabs(tabs, t)}
                    value={selectedTab.value}
                    onChange={handleSelect}
                />
                <div className={styles.action}>
                    <button
                        className={styles.btn}
                        onClick={handleClick}
                        disabled={
                            isDisabled ||
                            applicationData[0]?.status !== AWAITING_ACCEPTANCE
                        }
                    >
                        <Plus size={16} color="#9F9DA5" />
                        <span className={styles.label}>
                            {t('applications:add_box')}
                        </span>
                    </button>
                </div>
            </div>
            <Spin spinning={isFetching} text={t('loading')}>
                <Form
                    methods={methods}
                    onSubmit={mutation.mutate}
                    disabled={isDisabled}
                >
                    {selectedTab.type === CARRIAGE_TYPE && (
                        <Carriage
                            showSmall={isMobile}
                            carriageFields={carriageFields}
                            isDisabled={isDisabled}
                        />
                    )}
                    {selectedTab.type === BOX_TYPE && (
                        <CarriageContainer
                            showSmall={isMobile}
                            onDelete={handleDeleteTab}
                            containerIndex={selectedTab.value - 1}
                            methods={methods}
                            isDisabled={isDisabled}
                            key={selectedTab.value - 1}
                        />
                    )}
                </Form>
            </Spin>
        </div>
    );
};

function getTabs(tabs, t) {
    return tabs.map((tab) => {
        return {
            ...tab,
            label: `${t(tab.label)}${tab.subTitle ? ` ${tab.subTitle}` : ''}`
        };
    });
}

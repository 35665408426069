import api from 'src/core/api';
import { DataRequestDetailsNormalizer } from 'src/core/normalizers/DataRequest';
import { DATA_REQUEST_DATA_URL } from 'src/settings';

export const getCargosList = (params) => {
    return api.get(DATA_REQUEST_DATA_URL + '/cargo_apps', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getCargosListNonPaged = (params) => {
    return api.get(DATA_REQUEST_DATA_URL + '/non-paged/cargo_apps', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getCargoAppWalogList = (params) => {
    return api.get(
        DATA_REQUEST_DATA_URL + '/non-paged/cargo_app_wagon_detailed',
        {
            params,
            normalizer: DataRequestDetailsNormalizer
        }
    );
};

export const createApplication = (params) => {
    return api.post(`/cargo/api/cargos/applications`, params);
};

export const updateApplication = (params) => {
    return api.put(`/cargo/api/cargos/applications/${params.id}`, params);
};

export const deleteApplication = (id) => {
    return api.delete(`/cargo/api/cargos/applications/${id}`);
};

export const createCarriage = (params) => {
    return api.post(
        `/cargo/api/cargos/applications/${params.appId}/wagons/assign`,
        params
    );
};

export const deleteCarriage = (params) => {
    return api.delete(
        `/cargo/api/cargos/applications/${params.appId}/wagons/${params.id}`
    );
};

export const acceptApplication = (params) => {
    return api.post(
        `/cargo/api/cargos/applications/${params.id}/accept`,
        params
    );
};

export const removeProtection = (params) => {
    return api.post(
        `/cargo/api/cargos/applications/${params.appId}/wagons/${params.id}/detach`
    );
};

import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'sam-ui-kit';

import { Card } from 'components/Card/Card';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormSelectField } from 'components/Form/FormSelectField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { RequiredField } from 'components/Form/rules';

import { getStatusOptions } from 'modules/Applications/utils';

import { CarriageAttributes } from '../CarriageAttributes/CarriageAttributes';
import styles from './Carriage.module.scss';

export const Carriage = ({ showSmall, carriageFields, isDisabled }) => {
    const { fields, remove, append } = carriageFields;
    const { t } = useTranslation();

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={3} sm={12}>
                    <div className={styles.card}>
                        <Card
                            title={t('applications:carriage')}
                            hideActions={true}
                            size="md"
                        >
                            <FormAutocomplete
                                clearable
                                name="wagonCode"
                                label={t('applications:carriage')}
                                rules={RequiredField}
                                catalogName="WAGON_NUMBER_AVAILABLE"
                            />
                            <FormAutocomplete
                                clearable
                                name="defect"
                                label={t('applications:comment')}
                                catalogName="WAGON_DEFECTS"
                                rules={RequiredField}
                                useFullModel={true}
                            />
                            <FormTextareaField
                                label={t('note')}
                                name="remark"
                            />
                            <FormSelectField
                                label={t('status')}
                                name="initialStatus"
                                options={getStatusOptions(t)}
                                rules={RequiredField}
                            />
                            <Button
                                label={t('applications:delete_carriage')}
                                variant="secondary"
                                disabled={isDisabled}
                            />
                        </Card>
                        {!showSmall && (
                            <Button
                                label={t('actions.save_and_exit')}
                                variant="primary"
                                block={true}
                                type="submit"
                                disabled={isDisabled}
                                size="lg"
                            />
                        )}
                    </div>
                </Col>
                <Col span={9} sm={12}>
                    <CarriageAttributes
                        showSmall={showSmall}
                        fields={fields}
                        onDelete={remove}
                        onAppend={append}
                        isDisabled={isDisabled}
                    />
                </Col>
                {showSmall && (
                    <Col>
                        <Button
                            label={t('actions.save_and_exit')}
                            variant="primary"
                            block={true}
                            size="lg"
                            type="submit"
                            disabled={isDisabled}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

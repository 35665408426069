import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Table } from 'sam-ui-kit';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useSort } from 'hooks/useSort';

import { formatDate } from 'utils/dateUtils';
import { getFIOShort } from 'utils/textUtils';

import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { TextLink } from 'components/TextLink/TextLink';

import { SITUATION_PAGE_SIZE } from 'modules/Situations/components/consts';
import { getCargoEscortList } from 'modules/Situations/services';

import styles from './CargoEscortList.module.scss';

export const CargoEscortList = () => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const userStationCode = useSelector(
        (state) => state.auth.user.profileDataMap?.STATION_CODE
    );
    const [customFilters] = useQueryParam(
        'customFilters',
        withDefault(CustomFilterParam, {
            arrival_station_code_eq: userStationCode
        })
    );

    const cargoEscortFilters = useMemo(() => {
        if (customFilters?.arrival_station_code_eq) {
            return {
                arrival_station_code_eq: customFilters?.arrival_station_code_eq
            };
        } else {
            return {};
        }
    }, [customFilters]);

    const cargoEscort = useDataRequest('cargoEscortList', getCargoEscortList, {
        customFilters: cargoEscortFilters
    });

    const [sort, setSort] = useSort([{ id: 'appearanceDate', desc: true }]);

    return cargoEscort.isLoading ? (
        <SkeletonLoader width="100%" height={154}>
            <rect width="100%" height={140} rx={15} ry={15} y={14} />
        </SkeletonLoader>
    ) : (
        <div className={styles.situationsEscort} ref={ref}>
            <h1 className={styles.header}>
                {t('situations:escort')}
                <span className={styles.count}>
                    ({cargoEscort?.data?.length ?? t('no_rows')})
                </span>
            </h1>
            {cargoEscort.data?.length > 0 && (
                <Table
                    pageSize={SITUATION_PAGE_SIZE}
                    data={cargoEscort.data || []}
                    columns={getColumnsConfig(t)}
                    sorting={sort}
                    onSortingChange={setSort}
                />
            )}
        </div>
    );
};

function getColumnsConfig(t) {
    return [
        {
            header: t('situations:index'),
            accessorKey: 'indexPoezd',
            minSize: 200,
            size: 200
        },
        {
            header: t('situations:train_num'),
            cell: ({ row }) => (
                <TextLink
                    label={row.original.nomPoezd}
                    url={`/situations/${row.original.id}`}
                />
            ),
            enableSorting: true,
            size: 130,
            minSize: 130
        },
        {
            header: t('situations:security_person'),
            accessorKey: 'escortGuard.firstName',
            cell: ({ row }) => {
                if (row.original.escortGuard) {
                    return getFIOShort(row.original.escortGuard, {
                        f: 'lastName',
                        i: 'firstName',
                        o: 'secondName'
                    });
                }
                return '';
            },
            minSize: 150,
            size: 150,
            enableSorting: true
        },
        {
            header: t('to'),
            accessorKey: 'departureStation.name',
            enableSorting: true,
            size: 150,
            minSize: 150
        },
        {
            header: t('situations:departure'),
            cell: ({ row }) => formatDate(row.original.departureDate),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:arrival'),
            cell: ({ row }) => formatDate(row.original.escortArrivedDate),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:security'),
            accessorKey: 'guardedWagonsCount',
            enableSorting: true,
            size: 75,
            minSize: 75
        },
        {
            header: t('status'),
            accessorKey: 'escortArrived',
            cell: ({ row }) =>
                row.original.escortArrived
                    ? t('situations:arrived')
                    : t('situations:escort'),
            minSize: 150,
            size: 150,
            enableSorting: true
        }
    ];
}

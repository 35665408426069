import { pick } from 'lodash';
import { notifications } from 'sam-ui-kit';

import { getErrors } from 'utils/serverErrors';

import { StatusText } from 'components/StatusText/StatusText';

export const getStatusField = (data = {}, t) => {
    switch (data.status) {
        case 'NOT_ACCEPTED':
            return (
                <StatusText
                    label={t('statuses.not_accepted')}
                    variant="orange"
                />
            );
        case 'ARRIVING':
            return <StatusText label={t('statuses.arriving')} variant="grey" />;
        case 'ACCEPTED':
            return (
                <StatusText label={t('statuses.accepted')} variant="green" />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};

export const getStatusTextField = (data = {}, t) => {
    switch (data.status) {
        case 'NOT_ACCEPTED':
            return (
                <StatusText
                    label={t('statuses.not_accepted')}
                    variant="red"
                    noBackground={true}
                />
            );
        case 'ACCEPTED':
            return (
                <StatusText
                    label={t('statuses.accepted')}
                    variant="green"
                    noBackground={true}
                />
            );
        default:
            return <StatusText label={''} variant="" noBackground={true} />;
    }
};

export const notificateNaturalSheetErrorResponse = (response, t) => {
    const errors = getErrors(response);
    errors.forEach(({ message, fields }) => {
        let description;
        if (
            message ===
            'backendValidation.backend.validation.qorgau.naturSheet.wagonNotFound'
        ) {
            description =
                t(`backendValidation:${message}`) + ': ' + fields.join(', ');
        } else {
            description = t([
                `backendValidation:${message}`,
                'backendValidation:fallbackValidationError'
            ]);
        }
        notifications.push({
            type: 'error',
            title: t('error'),
            description
        });
    });
};

export const getSituationDepartureModel = (data) => {
    let result;
    if (data.isDepStation) {
        result = pick(data, [
            'departureDate',
            'isDepStation',
            'departureStation',
            'escortGuard'
        ]);
    } else {
        result = pick(data, ['departureDate', 'isDepStation', 'departurePark']);
    }
    if (result.isDepStation !== true) {
        result.isDepStation = false;
    }
    return result;
};

export const wagonRueqestToFormData = (data) => {
    const result = pick(data, [
        'id',
        'wagonCode',
        'wagonAcceptanceInSituation',
        'wagonAcceptanceInSituationDefect',
        'wagonAcceptanceInSituationText',
        'acceptable'
    ]);

    if (result.wagonAcceptanceInSituation === true) {
        result.wagonAcceptanceInSituation = 'true';
    } else if (result.wagonAcceptanceInSituation === false) {
        result.wagonAcceptanceInSituation = 'false';
    }
    return result;
};

export const wagonFormDataToRequest = (data) => {
    const result = { ...data };
    if (result.wagonAcceptanceInSituation === 'true') {
        result.wagonAcceptanceInSituation = true;
    } else if (result.wagonAcceptanceInSituation === 'false') {
        result.wagonAcceptanceInSituation = false;
    }
    return result;
};

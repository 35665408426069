import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'sam-ui-kit';

import { Card } from 'components/Card/Card';
import { FormInputField } from 'components/Form/FormInputField';
import { FormTextareaField } from 'components/Form/FormTextareaField';

import { ContainerAttributes } from '../ContainerAttributes/ContainerAttributes';
import styles from './CarriageContainer.module.scss';
import { RequiredField } from 'components/Form/rules';

export const CarriageContainer = ({
    showSmall,
    containerIndex,
    onDelete,
    methods,
    isDisabled
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={[16, 16]}>
            <Col span={3} sm={12}>
                <div className={styles.card}>
                    <Card
                        title={t('applications:box')}
                        hideActions={true}
                        size="md"
                    >
                        <FormInputField
                            label={t('applications:box_number')}
                            placeholder={t('applications:box_number_edit')}
                            name={`containers.${containerIndex}.containerCode`}
                            rules={RequiredField}
                        />

                        <FormTextareaField
                            label={t('note')}
                            name={`containers.${containerIndex}.remark`}
                        />

                        <Button
                            label={t('applications:delete_box')}
                            variant="secondary"
                            onClick={() => onDelete(containerIndex)}
                            disabled={isDisabled}
                        />
                    </Card>
                    {!showSmall && (
                        <Button
                            label={t('actions.save_and_exit')}
                            variant="primary"
                            block={true}
                            size="lg"
                            type="submit"
                            disabled={isDisabled}
                        />
                    )}
                </div>
            </Col>
            <Col span={9} sm={12}>
                {
                    <ContainerAttributes
                        showSmall={showSmall}
                        containerIndex={containerIndex}
                        methods={methods}
                        isDisabled={isDisabled}
                    />
                }
            </Col>
            {showSmall && (
                <Col>
                    <Button
                        label={t('actions.save_and_exit')}
                        variant="primary"
                        block={true}
                        size="lg"
                        type="submit"
                        disabled={isDisabled}
                    />
                </Col>
            )}
        </Row>
    );
};

import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';
import { formatDate } from 'src/utils/dateUtils';

import { CellField } from 'components/CellField/CellField';
import { TableRowEditControls } from 'components/TableRowEditControls/TableRowEditControls';

import { DefectStatus } from 'modules/Defects/components/DefectStatus';
import { DefectsEditModal } from 'modules/Defects/components/DefectsEditModal/DefectsEditModal';
import { DEFECTS_PAGE_SIZE } from 'modules/Defects/consts';

import styles from './DefectsTable.module.scss';

export const DefectsTable = ({ isLoading, defects, sort, setSort }) => {
    const { t } = useTranslation();

    const handleEdit = (id) => {
        showModal(DefectsEditModal, { id }).then(noop, noop);
    };

    return (
        <div className={styles.table}>
            <Table
                loading={isLoading}
                data={defects ?? []}
                columns={getColumnsConfig(t, handleEdit)}
                pageSize={DEFECTS_PAGE_SIZE}
                sorting={sort}
                onSortingChange={setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t, onEdit) => {
    return [
        {
            header: t('station'),
            accessorKey: 'station',
            cell: ({ row }) => <CellField value={row.original.station?.name} />,
            enableSorting: true
        },
        {
            header: t('defects:park'),
            accessorKey: 'park',
            cell: ({ row }) => <CellField value={row.original.park?.nameRu} />,
            enableSorting: true
        },
        {
            header: t('date'),
            accessorKey: 'createdAt',
            cell: ({ row }) => (
                <CellField value={formatDate(row.original.createdAt)} />
            ),
            enableSorting: true
        },
        {
            header: t('defects:security'),
            accessorKey: 'guardUsername',
            enableSorting: true
        },
        {
            header: t('defects:carriage'),
            accessorKey: 'wagonCode',
            enableSorting: true
        },
        {
            header: t('description'),
            accessorKey: 'defect',
            cell: ({ row }) => (
                <CellField value={formatDate(row.original.defect?.name)} />
            ),
            enableSorting: true
        },
        {
            header: t('status'),
            accessorKey: 'status',
            cell: ({ row }) => <DefectStatus status={row.original.status} />,
            enableSorting: true
        },
        {
            header: `№ ${t('defects:aof')}`,
            accessorKey: 'docNumber',
            enableSorting: true
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                return (
                    <TableRowEditControls
                        onEdit={() => onEdit(row.original.id)}
                    />
                );
            },
            size: 30,
            minSize: 30
        }
    ];
};

import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';

import { notificateErrorResponse } from 'utils/serverErrors';

import { CardValue } from 'components/CardValue/CardValue';
import { Divider } from 'components/Divider/Divider';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { RequiredField } from 'components/Form/rules';
import { StatusText } from 'components/StatusText/StatusText';

import { REJECTED } from 'modules/Applications/consts';
import { WagonAcceptanceInSituationDetails } from 'modules/Situations/components/WagonAcceptanceInSituationDetails/WagonAcceptanceInSituationDetails';
import { CarriageModal } from 'modules/Situations/components/СarriageModal/СarriageModal';
import { detachSituationWagons } from 'modules/Situations/services';

import styles from './WagonCard.module.scss';

export const WagonCard = ({
    field,
    index,
    wagon,
    situationId,
    refetch,
    situationAccepted
}) => {
    const { t } = useTranslation();

    const methods = useFormContext();

    const wagonAcceptanceInSituation = useWatch({
        name: `wagons.${index}.wagonAcceptanceInSituation`,
        control: methods.control
    });

    useEffect(() => {
        if (wagonAcceptanceInSituation === 'true') {
            methods.clearErrors(
                `wagons.${index}.wagonAcceptanceInSituationDefect`
            );
        }
    }, [wagonAcceptanceInSituation, methods, index]);

    const detachMutation = useMutation({
        mutationFn: () =>
            detachSituationWagons({ situationId, wagonId: wagon.id }),
        onSuccess: refetch,
        onError: (response) => notificateErrorResponse(response, t)
    });

    const handleOpenWagon = () => {
        showModal(CarriageModal).then(noop, noop);
    };

    const renderStatus = () => {
        return wagon.initialWagonAcceptanceStatus ? (
            <StatusText
                label={t(
                    `applications:status_cargo.${wagon.initialWagonAcceptanceStatus}`
                )}
                variant={
                    wagon.initialWagonAcceptanceStatus === REJECTED
                        ? 'red'
                        : 'green'
                }
                textAlign="right"
                noBackground={true}
            />
        ) : (
            ''
        );
    };

    const renderDetachInfo = () => {
        if (!wagon.detached && wagon.detachable) {
            return (
                <div>
                    <Divider />
                    <Button
                        label={t('situations:remove_protection')}
                        block={true}
                        onClick={detachMutation.mutate}
                        disabled={detachMutation.isPending}
                        variant="default"
                        size="lg"
                    />
                </div>
            );
        }
        if (wagon.detached) {
            return (
                <>
                    <Divider />
                    <div className={styles.detached}>
                        {t('situations:removed_under_protection')}
                    </div>
                </>
            );
        }
    };

    return (
        <div className={styles.wagonCard}>
            <div className={styles.title} onClick={handleOpenWagon}>
                {index + 1}.
                <span className={styles.wagon}>{wagon.wagonCode}</span>
            </div>
            <div className={styles.subtitle}>
                {field.cargoCode
                    ? `${wagon.cargoCode.code}-${wagon.cargoCode.name}`
                    : ''}
            </div>
            <Divider classNames={styles.divider} />

            <div className={styles.info}>
                <CardValue
                    label={t('situations:initial_acceptance_short')}
                    value={renderStatus()}
                />
                <CardValue
                    label={t('situations:defect')}
                    value={
                        wagon.initialWagonAcceptanceStatusDefect?.name || (
                            <span className={styles.greyText}>
                                {t('actions.no')}
                            </span>
                        )
                    }
                />
                <CardValue
                    label={t('situations:note')}
                    value={
                        <span
                            className={clsx(
                                styles.note,
                                styles.greyText &&
                                    !wagon.initialWagonAcceptanceStatusText
                            )}
                            title={wagon.initialWagonAcceptanceStatusText}
                        >
                            {wagon.initialWagonAcceptanceStatusText ||
                                t('actions.no')}
                        </span>
                    }
                />

                <Divider classNames={styles.divider} />

                <CardValue
                    label={t('situations:prev_situation_short')}
                    value={
                        wagon.wagonAcceptanceStatusInLastSituation
                            ? t('statuses.accepted')
                            : t('statuses.not_accepted')
                    }
                />
                <CardValue
                    label={t('situations:defect')}
                    value={
                        wagon.wagonAcceptanceStatusInLastSituationDefect
                            ?.name || (
                            <span className={styles.greyText}>
                                {t('actions.no')}
                            </span>
                        )
                    }
                />
                <CardValue
                    label={t('situations:note')}
                    value={
                        <span
                            className={clsx(
                                styles.note,
                                styles.greyText &&
                                    !wagon.wagonAcceptanceStatusInLastSituationText
                            )}
                            title={
                                wagon.wagonAcceptanceStatusInLastSituationText
                            }
                        >
                            {wagon.wagonAcceptanceStatusInLastSituationText ||
                                t('actions.no')}
                        </span>
                    }
                />
            </div>

            {situationAccepted ? (
                <div>
                    <Divider />
                    <WagonAcceptanceInSituationDetails wagon={wagon} />
                </div>
            ) : wagon.acceptable ? (
                <div>
                    <Divider />
                    <div className={styles.controls}>
                        <FormSegmentControlField
                            name={`wagons.${index}.wagonAcceptanceInSituation`}
                            segmentsConfig={[
                                {
                                    label: t('actions.accepting'),
                                    value: 'true'
                                },
                                {
                                    label: t('actions.not_accepting'),
                                    value: 'false'
                                }
                            ]}
                            variant="button"
                            rules={RequiredField}
                            disableHint
                            noHintSpace
                        />
                        <FormAutocomplete
                            clearable
                            name={`wagons.${index}.wagonAcceptanceInSituationDefect`}
                            catalogName="WAGON_DEFECTS"
                            rules={RequiredField}
                            useFullModel
                            disableHint
                            noHintSpace
                        />
                        <FormTextareaField
                            name={`wagons.${index}.wagonAcceptanceInSituationText`}
                            rows={2}
                            placeholder={t('situations:enter_note_if_exists')}
                            noHintSpace={true}
                        />
                    </div>
                </div>
            ) : (
                renderDetachInfo()
            )}
        </div>
    );
};

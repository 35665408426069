import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Space } from 'sam-ui-kit';

import { Divider } from 'components/Divider/Divider';

import styles from './WagonStatistic.module.scss';

export const WagonStatistic = ({ isMobile, data, formWagons }) => {
    const { t } = useTranslation();
    const removedUnderProtectionCount = data.wagons.filter(
        ({ detached }) => detached
    ).length;

    const acceptableCount = data.wagons.filter(
        ({ acceptable }) => acceptable
    ).length;

    const acceptedCount = formWagons.filter(
        ({ acceptable, wagonAcceptanceInSituation }) => {
            return acceptable && wagonAcceptanceInSituation === 'true';
        }
    ).length;

    const notAcceptedCount = formWagons.filter(
        ({ acceptable, wagonAcceptanceInSituation }) => {
            return acceptable && wagonAcceptanceInSituation === 'false';
        }
    ).length;

    return (
        <h3
            className={clsx(styles.title, {
                [styles.mobileView]: isMobile
            })}
        >
            {isMobile ? (
                <>
                    <span>
                        {t('situations:accepted_carriages')}: {acceptedCount}/
                        {acceptableCount}
                    </span>
                    <Divider classNames={styles.divider} />
                    <span className={styles.greyText}>
                        {`${t('situations:initial_acceptance_defects')}: 0`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:prev_acceptance_defects')}: ${0}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:removed_under_protection')}: ${removedUnderProtectionCount}`}
                    </span>
                    <span className={styles.greyText}>
                        {t('actions.not_accepting')}: {notAcceptedCount}
                    </span>
                </>
            ) : (
                <Space displayDivider={true} gap={24}>
                    <span>
                        {t('situations:accepted_carriages')}: {acceptedCount}/
                        {acceptableCount}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:initial_acceptance_defects')}: 0`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:prev_acceptance_defects')}: ${0}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:removed_under_protection')}: ${removedUnderProtectionCount}`}
                    </span>
                    <span className={styles.greyText}>
                        {t('actions.not_accepting')}: {notAcceptedCount}
                    </span>
                </Space>
            )}
        </h3>
    );
};

import { SituationDetailsPage } from 'modules/Situations/pages/SituationDetailsPage/SituationDetailsPage';
import { SituationsDashboardPage } from 'modules/Situations/pages/SituationsDashboardPage';

export const routes = [
    {
        path: '/',
        element: <SituationsDashboardPage />
    },
    {
        path: '/situations/:id',
        element: <SituationDetailsPage />
    }
];

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Card } from 'components/Card/Card';
import { TextButton } from 'components/TextButton/TextButton';

import {
    DEFAULT_SEAL,
    DEFAULT_TWIST,
    LEFT_DIRECTION,
    RIGHT_DIRECTION,
    SEAL_TYPE,
    TWIST_TYPE
} from 'modules/Applications/consts';

import { SealAttributesItem } from '../SealAttributesItem/SealAttributesItem';
import { TwistAttributesItem } from '../TwistAttributesItem/TwistAttributesItem';
import styles from './CarriageAttributes.module.scss';

export const CarriageAttributes = ({
    showSmall,
    fields,
    onAppend,
    onDelete,
    isDisabled
}) => {
    const { t } = useTranslation();

    const handleAddSeal = (side) => {
        onAppend({
            ...DEFAULT_SEAL,
            sealSide: side
        });
    };

    const handleAddTwist = (side) => {
        onAppend({
            ...DEFAULT_TWIST,
            twistSide: side
        });
    };

    const handleDelete = (index) => {
        onDelete(index);
    };

    return (
        <div className={styles.attributes}>
            <Card
                title={t('applications:left_side')}
                hideActions={true}
                size="md"
                headerDirection={showSmall ? 'vertical' : 'horizontal'}
            >
                {fields.map((attribute, i) => {
                    let result = null;
                    if (
                        attribute.sealSide === RIGHT_DIRECTION ||
                        attribute.twistSide === RIGHT_DIRECTION
                    ) {
                        return null;
                    }
                    if (attribute.type === SEAL_TYPE) {
                        result = (
                            <SealAttributesItem
                                data={attribute}
                                onDelete={handleDelete}
                                showSmall={showSmall}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                                isDisabled={isDisabled}
                            />
                        );
                    }
                    if (attribute.type === TWIST_TYPE) {
                        result = (
                            <TwistAttributesItem
                                data={attribute}
                                onDelete={handleDelete}
                                showSmall={showSmall}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                                isDisabled={isDisabled}
                            />
                        );
                    }
                    if (i === fields.length - 1) {
                        return result;
                    }

                    return (
                        <>
                            {result}
                            <div className={clsx('divider', styles.divider)} />
                        </>
                    );
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            showSmall && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
            <Card
                title={t('applications:right_side')}
                hideActions={true}
                size="md"
                headerDirection={showSmall ? 'vertical' : 'horizontal'}
            >
                {fields.map((attribute, i) => {
                    let result = null;
                    if (
                        attribute.sealSide === LEFT_DIRECTION ||
                        attribute.twistSide === LEFT_DIRECTION
                    ) {
                        return null;
                    }
                    if (attribute.type === SEAL_TYPE) {
                        result = (
                            <SealAttributesItem
                                data={attribute}
                                onDelete={handleDelete}
                                showSmall={showSmall}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                                isDisabled={isDisabled}
                            />
                        );
                    }
                    if (attribute.type === TWIST_TYPE) {
                        result = (
                            <TwistAttributesItem
                                data={attribute}
                                onDelete={handleDelete}
                                showSmall={showSmall}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                                isDisabled={isDisabled}
                            />
                        );
                    }
                    if (i === fields.length - 1) {
                        return result;
                    }
                    return (
                        <>
                            {result}
                            <div className={clsx('divider', styles.divider)} />
                        </>
                    );
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            showSmall && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
        </div>
    );
};

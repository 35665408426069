import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Spin } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Card } from 'components/Card/Card';

import { REJECTED } from 'modules/Applications/consts';
import {
    acceptApplication,
    getCargoAppWalogList,
    removeProtection
} from 'modules/Applications/services/services';
import { getApplicationStatusField } from 'modules/Applications/utils';

import { CarriageItem } from '../CarriageItem/CarriageItem';
import { ReceptionCardContent } from '../ReceptionCardContent/ReceptionCardContent';
import { ReceptionForm } from '../ReceptionForm/ReceptionForm';
import styles from './Reception.module.scss';

export const Reception = ({ applicationIsFetching, applicationData = {} }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    const { id } = useParams();

    const {
        data,
        isFetching: wagonListIsFetching,
        refetch
    } = useQuery({
        queryKey: ['getCargoAppWalogList', id],
        queryFn: () => getCargoAppWalogList({ app_id_eq: id }),
        enabled: !!id,
        initialData: []
    });

    const acceptApplicationMutation = useMutation({
        mutationFn: (state) => {
            return acceptApplication(state);
        },
        onSuccess: () => navigate('/applications')
    });

    const removeProtectionMutation = useMutation({
        mutationFn: (state) => {
            return removeProtection(state);
        },
        onSuccess: refetch
    });

    const isLoading =
        applicationIsFetching ||
        wagonListIsFetching ||
        acceptApplicationMutation.isPending ||
        removeProtectionMutation.isPending;

    return (
        <Spin spinning={isLoading} text={t('loading')}>
            <div className="flex-column gap-16">
                {isMobile && (
                    <Card
                        title={`${t('applications:application')} ${id}`}
                        status={getApplicationStatusField(
                            {
                                status: applicationData.status
                            },
                            t
                        )}
                        hideActions={true}
                    >
                        {<ReceptionCardContent data={applicationData} />}
                    </Card>
                )}
                <div className={styles.statistics}>
                    <div className={styles.statistic}>
                        <span
                            className={styles.added}
                        >{`${t('applications:added')}: `}</span>
                        <span className={styles.added}>{data.length}</span>
                    </div>
                    <div className={styles.statistic}>
                        <span
                            className={styles.accepted}
                        >{`${t('applications:accepted')}: `}</span>
                        <span className={styles.accepted}>
                            {
                                data.filter(
                                    (item) => item.initialStatus !== REJECTED
                                ).length
                            }
                        </span>
                    </div>
                </div>
                <div>
                    <Row gutter={[16, 16]}>
                        <Col span={9} sm={12}>
                            <div className={styles.carriages}>
                                <Row gutter={[16, 16]}>
                                    {data?.map((item, i) => {
                                        return (
                                            <Col span={4} md={12} sm={12}>
                                                <CarriageItem
                                                    number={i + 1}
                                                    data={item}
                                                    refetch={refetch}
                                                    status={
                                                        applicationData.status
                                                    }
                                                    onRemoveProtection={
                                                        removeProtectionMutation.mutate
                                                    }
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </Col>
                        <Col span={3} md={12} sm={12}>
                            <div className={styles.form}>
                                {!isMobile && (
                                    <Card
                                        title={`${t('applications:application')} ${id}`}
                                        status={getApplicationStatusField(
                                            {
                                                status: applicationData.status
                                            },
                                            t
                                        )}
                                        hideActions={true}
                                    >
                                        <ReceptionCardContent
                                            data={applicationData}
                                        />
                                    </Card>
                                )}
                                <ReceptionForm
                                    data={applicationData}
                                    isLoading={isLoading}
                                    mutation={acceptApplicationMutation}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    );
};

import useResizeObserver from '@react-hook/resize-observer';
import { useDispatch, useSelector } from 'react-redux';

import { screenSizeActions } from 'core/reducers/screenSize';

import { LARGE_BREAKPOINT, MIDDLE_BREAKPOINT } from '../settings';

export const useScreenSizeObserver = () => {
    const dispatch = useDispatch();
    const size = useSelector((state) => state.screenSize.size);

    useResizeObserver(document.body, (entry) => {
        const width = window.innerWidth;
        console.log(entry);
        if (width < MIDDLE_BREAKPOINT && size !== 'MOBILE') {
            dispatch(screenSizeActions.setScreenSize('MOBILE'));
        } else if (
            width >= MIDDLE_BREAKPOINT &&
            width < LARGE_BREAKPOINT &&
            size !== 'DESKTOP'
        ) {
            dispatch(screenSizeActions.setScreenSize('DESKTOP'));
        } else if (width >= LARGE_BREAKPOINT && size !== 'DESKTOP_XL') {
            dispatch(screenSizeActions.setScreenSize('DESKTOP_XL'));
        }
    });
};
